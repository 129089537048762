<template>
  <div class="mb-6">
    <div class="flex justify-between mb-2">
      <div class="flex flex-wrap items-center">
        <div class="font-semibold sm:text-lg">{{ bill.store?.name }}</div>
      </div>
      <div v-if="showNumberBill">
        <div dir="ltr" class="flex sm:text-lg">
          <div>No:</div>
          <div class="font-semibold mx-0.5 text-gray-500">
            {{ bill.bill_number }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="px-2 rounded-md mb-2 bg-nav_color bg-opacity-10 max-w-max">
          {{ t(bill.stringState ?? '') }}
        </div>
      </div>
    </div>
    <div class="text-sm sm:text-base mx-1">
      <div class="flex justify-between">
        <div class="flex flex-wrap items-center">
          <div class="font-medium">- {{ t('number_of_materials') }}:</div>
          <div class="mx-2">{{ bill.bill_details_count }}</div>
        </div>
        <div
          v-if="showNumberBill"
          class="px-2 rounded-md mb-2 bg-nav_color bg-opacity-10 max-w-max"
        >
          {{ t(bill.stringState ?? '') }}
        </div>
      </div>
      <div class="flex flex-wrap items-center">
        <div class="font-medium">- {{ t('order_date') }}:</div>
        <div class="mx-2">{{ bill.stringDate }}</div>
      </div>

      <div class="flex flex-wrap items-center">
        <div class="font-medium">- {{ t('order_status') }}:</div>
        <div class="mx-2">{{ t(bill.stringState ?? '') }}</div>
      </div>
      <div
        v-if="bill.cancel_reason?.trim()?.length"
        class="flex flex-wrap items-center"
      >
        <div class="font-medium">- {{ t('reason_for_cancellation') }}:</div>
        <div class="mx-2">{{ t(bill.cancel_reason ?? '') }}</div>
      </div>
      <div class="flex flex-wrap items-center">
        <div class="font-medium">- {{ t('pay_status') }}:</div>
        <div class="mx-2">{{ t(bill.paySatus ?? '') }}</div>
      </div>
      <div v-if="bill.payment_details" class="flex flex-wrap items-center">
        <div class="font-medium">- {{ t('pay_details') }}:</div>
        <div class="mx-2">{{ t(bill.payment_details ?? '') }}</div>
      </div>
      <div v-if="location" class="flex flex-wrap items-center">
        <div class="font-medium">- {{ t('name_location') }}:</div>
        <div class="mx-2">{{ location }}</div>
      </div>

      <div class="flex flex-wrap items-center">
        <div class="font-medium">- {{ t('shipping_cost') }}:</div>
        <div class="mx-2">{{ costShippingTitleBill }}</div>
      </div>

      <div class="flex flex-wrap items-center">
        <div class="font-medium">- {{ t('order_total') }}:</div>
        <div class="mx-2">{{ bill.stringPaidAmount ?? '' }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePlacesStore } from '~/store/places'

const { getPlaceById } = usePlacesStore()
const props = withDefaults(
  defineProps<{
    bill: UserOrder
    showNumberBill?: boolean
  }>(),
  {
    showNumberBill: true
  }
)
const location = computed(() => {
  return getPlaceById(props.bill.agent_place_id)?.details
})
const costShippingTitleBill = computed(() => {
  let costDelviery = t('unspecified')
  if (parseFloat(props.bill.shipping_amount?.toString() || '') > 0) {
    costDelviery = props.bill.stringShippingAmount || ''
  }
  return costDelviery
})

const { t } = useI18n()
</script>
