<template>
  <EStoreFieldError v-slot="{ hasError }" :name="error">
    <label>
      <span
        class="text-sm font-semibold text-e_store-light"
        :class="{ 'text-red-400': hasError }"
      >
        {{ label }}
      </span>
    </label>
    <div class="relative">
      <textarea
        class="ltr:text-left rtl:text-right"
        placeholder="Comment text."
        :disabled="disabled"
        :class="[{ error: hasError },classText]"
        v-bind="$attrs"
        :value="modelValue"
        @input="updateValue"
      >

      </textarea>
    </div>
  </EStoreFieldError>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
withDefaults(
  defineProps<{
    label: string
    modelValue: string
    classText?: string
    disabled: boolean
    error?: string | undefined
  }>(),
  {
    label: '',
    classText: 'e_store-text-field',
    disabled: false,
    error: undefined
  }
)

const emit = defineEmits(['update:modelValue'])

const updateValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
}
</script>
